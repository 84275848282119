/* You can add global styles to this file, and also import other style files */
html,body { height: auto !important; }
$font_family: 'Proxima Nova', HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue,
  Helvetica, Arial, Lucida Grande, 'sans-serif';
use {
  pointer-events: none;
}

body,
p-table {
  font-weight: normal;
  font-family: 'benton-sans-condensed', sans-serif;
}

.healthcare-top-bar-button {
  color: white !important;
}

#healthcare-top-bar {
  .healthcare-top-bar-button {
    &:hover {
      color: #00afdb;
      background-color: #0086a8 !important;
      border-color: #00afdb;
      transition: background-color 100ms;
    }
  }
}

.healthcareLeader {
  background: url('/assets/images/healthcare.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  filter: "progid: DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/healthcare.jpg', sizingMethod='scale')";
  background-size: cover;
  margin-bottom: 80px;
  text-align: center;
  h1 {
      color: white;
      font-size: 54px;
      line-height: 1em;
      text-shadow: 0px 0px 5px rgba(0, 0, 1, 0.4);
      letter-spacing: -0.02em;
      font-weight: bold;
      padding-top: 128px;
      font-family: $font_family;
      margin: 0px 15px;
  }
  h2 {
      color: white;
      font-size: 26px;
      line-height: 1em;
      text-shadow: 0px 0px 5px rgba(0, 0, 1, 0.4);
      letter-spacing: -0.02em;
      padding-bottom: 128px;
      font-family: $font_family;
      margin: 15px;
  }
  div {
      background-color: #303584;
      padding: 50px 0px;
      span {
          color: white;
          font-family: $font_family;
          font-size: 24px;
          line-height: 31.2px;
          max-width: 1140px;
          margin: 15px;
          text-align: left;
          display: inline-block;
      }
  }
}

.inm-input,
.inm-input__field,
.inm-input--warning {
  font-size: 1em;
  font-weight: normal;
  font-family: sans-serif !important;
  color: #000;
  width: 80%;
}

.inm-input--warning {
  border-color: #e2623d;
}

.form .ui-dropdown {
  width: 80% !important;
}

.ui-inputtext {
  height: 35px;
  padding-top: 8px;
}

.form-button-strip {
  float: right;
}

.hspacer {
  height: 10px;
}

.danger {
  color: #e2623d;
}

.ui-dropdown .ui-state-focus {
  border: 1px solid #000;
}

// Table Classes
.default {
  text-align: center;
}

.money {
  text-align: right;
  overflow: auto;
}

.yesno-hdr {
  text-align: center;
  width: 75px;
}

.overflow {
  text-align: center;
  overflow: unset;
  white-space: unset;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.column-icon-btn {
  margin: 0;
  text-align: center;
  width: 80px;
}

.grid-checkbox {
  width: 35px;
  text-align: center;
}

.ui-paginator .ui-paginator-page {
  width: 3.7em;
}

.space-it {
  margin-right: 15px;
}

.col-link {
  cursor: pointer;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.btn-container-dialog {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  flex-basis: auto;
  flex-grow: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  margin-top: 20px;
  margin-left: 8px;
}

.btn-padding {
  margin-left: 15px;
}

p-accordiontab > div > a {
  display: flex !important;
  flex-direction: row !important;
}

:host ::ng-deep .ui-fileupload-content {
  overflow-y: auto !important;
}

.ui-button-text-icon-left,
.ui-fileupload-choose.ui-button,
.ui-fileupload-choose.ui-button .ui-widget,
.ui-fileupload-choose.ui-button .ui-state-default {
  background: #fff !important;
  color: #038489 !important;
  border: 0.1rem solid transparent;
  border-color: currentColor;
  font-family: 'benton-sans-condensed', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 1em;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.6rem;
  -webkit-transition: all 0.125s ease-out;
  transition: all 0.125s ease-out;
  outline: none;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  white-space: nowrap;
  border-radius: 1.1em;
}

.ui-fileupload-choose.ui-button:hover,
.ui-fileupload-choose.ui-button:focus,
.ui-fileupload-choose.ui-button.ui-state-active {
  -webkit-box-shadow: 0 0.6rem 2.2rem -1rem #038489 !important;
  box-shadow: 0 0.6rem 2.2rem -1rem #038489 !important;
}

.ui-button-text-icon-left:hover,
.ui-button-text-icon-left:focus,
.ui-button-text-icon-left.ui-state-active {
  -webkit-box-shadow: 0 0.6rem 2.2rem -1rem #038489;
  box-shadow: 0 0.6rem 2.2rem -1rem #038489;
}

.ui-listbox-header {
  height: 31px !important;
}

.ui-inputtext {
  margin-top: 0px;
}

_:-ms-fullscreen,
.ui-inputtext {
  margin-top: 2px;
}

.mat-sort-header-content,
.mat-header-cell {
  margin: auto;
  text-align: center !important;
}

td.mat-cell {
  text-align: center !important;
  padding: 0.25em 0.25em !important;
}

.mat-tooltip {
  font-size: 15px !important;
}

.inm-expandable__title, [class*=' inm-expandable__title--'], [class^=inm-expandable__title--] {
  position: relative;
  padding: 0 1rem;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 3.6rem;
  text-decoration: none;
  text-transform: none !important;
  cursor: pointer;
  color: #303584;
  font-weight: 500;
  font-family: benton-sans-condensed,sans-serif;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;
  border-radius: 0.3rem;
  border: 0.1rem solid transparent;
  border-bottom: 1px solid #c7d0d9;
  overflow-wrap: anywhere;
}
